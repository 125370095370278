import { graphql, useStaticQuery } from 'gatsby'

export const getArtists = () => {
  const data = useStaticQuery(
    graphql`
      {
        allArtist(
          filter: {
            deleted: { ne: true }
            visible: { eq: true }
            join20241: { eq: true }
          }
        ) {
          nodes {
            id
            deleted
            visible
            last_name
            first_name
            pseudonym
            portrait_file
            previewImage
            vita_short
          }
        }
      }
    `
  )
  return (
    data?.allArtist?.nodes.sort((a, b) =>
      (a.pseudonym || a.last_name).localeCompare(b.pseudonym || b.last_name)
    ) || []
  )
}
