import { graphql, useStaticQuery } from 'gatsby'

// !!!
// 2 galerie versionen git historie

export const getArtworks = () => {
  const data = useStaticQuery(
    graphql`
      {
        allArtwork(
          filter: {
            deleted: { ne: true }
            fair: { eq: "2024-1" }
          }
        ) {
          nodes {
            id
            artistId
            fair
            title
            technique
            width
            height
            depth
            price
            porto
            portoActive
            info
            files
            artworkIndex
            deleted
            visible
            hidden
            catalog
            catalogTop
          }
        }
      }
    `
  )
  return data?.allArtwork?.nodes || []
}
